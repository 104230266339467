<template>
  <b-modal
    id="product-create-modal"
    ref="product-create-modal"
    title="Create"
    centered
    hide-footer
    no-close-on-backdrop
    size="lg"
    @shown="doLoadData"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer">
      <b-form @submit.stop.prevent>

        <b-row>
          <!-- !! Name -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <HeroInputText
                id="name"
                v-model="product.name"
                label="Name"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Product Code -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="product code"
              rules="required"
            >
              <HeroInputText
                id="product-code"
                v-model="product.product_code"
                label="Product Code"
                column="4"
                :required="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Product Family -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="product family"
              rules="required"
            >
              <HeroVueSelect
                id="product-family-id"
                v-model="product.product_family_id"
                label="Product Family"
                column="4"
                :required="true"
                :clearable="false"
                :options="$store.getters['ProductFamilyOptions/getOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Description -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="description"
              rules=""
            >
              <HeroInputText
                id="description"
                v-model="product.description"
                label="Description"
                column="4"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Ad Type -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="ad type"
              rules=""
            >
              <HeroInputText
                id="ad-type"
                v-model="product.ad_type"
                label="Ad Type"
                column="4"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Campaign Name Convention -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="campaign name convention"
              rules=""
            >
              <HeroInputText
                id="campaign-name-convention"
                v-model="product.campaign_name_convention"
                label="Campaign Name Convention"
                column="4"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- !! Media Plan Convention -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="media plan convention"
              rules=""
            >
              <HeroInputText
                id="media-plan-convention"
                v-model="product.media_plan_convention"
                label="Media Plan Convention"
                column="4"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <!-- !! KPI Unit Map Metric -->
        <b-row
          v-for="(map, index) in maps"
          :id="map.id"
          :key="map.id"
          ref="metricRow"
        >
          <b-col cols="5">
            <validation-provider
              #default="{ errors }"
              name="kpi"
              rules="required"
            >
              <HeroVueSelect
                :id="`map-property-${map.id}`"
                v-model="map.property"
                label="KPI Unit"
                :clearable="false"
                :options="$store.getters['KpiUnitOptions/getOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="5">
            <validation-provider
              #default="{ errors }"
              name="metric"
              rules="required"
            >
              <HeroVueSelect
                :id="`map-value-${map.id}`"
                v-model="map.value"
                label="Actual Convention Mapping"
                :clearable="false"
                :options="$store.getters['MetricUnitOptions/getOptions']"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="2">
            <HeroButtonAction class="mt-2" type="button" variant="primary" @click="deleteRow(index)">
              <feather-icon icon="TrashIcon" />
            </HeroButtonAction>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" offset-sm="8" sm="4">
            <HeroButtonAction type="button" variant="primary" @click="addRow">
              Add New Mapping
            </HeroButtonAction>
          </b-col>
        </b-row>

        <b-row>
          <!-- !! Active -->
          <b-col cols="12">
            <HeroCheckbox
              id="is-active"
              v-model="product.is_active"
              label="Is Active"
              column="4"
            />
          </b-col>

          <!-- !! Channel -->
          <b-col cols="12">
            <HeroCheckbox
              id="is-channel"
              v-model="product.is_channel"
              label="Is Channel"
              column="4"
            />
          </b-col>

          <!-- !! Media -->
          <b-col cols="12">
            <HeroCheckbox
              id="is-media"
              v-model="product.is_media"
              label="Is Media"
              column="4"
            />
          </b-col>
        </b-row>

        <!-- !! Footer -->
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction type="button" variant="primary" @click="validationForm">
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction type="button" variant="outline-secondary" @click="doCloseModal">
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import ErrorService from '@/services/ErrorService'
import AxiosBodyService from '@/services/AxiosBodyService'
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroCheckbox from '@/views/components/form/checkboxs/HeroCheckbox.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import SweetAlert from '@/services/SweetAlert'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    HeroInputText,
    HeroVueSelect,
    HeroCheckbox,
    HeroButtonAction,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      // Overlay
      showOverlay: false,

      // Validation
      required,

      original: {
        name: '',
        product_code: '',
        product_family_id: '',
        description: '',
        ad_type: '',
        campaign_name_convention: '',
        media_plan_convention: '',
        kpi_unit_map_metric: {},
        is_active: false,
        is_channel: false,
        is_media: false,
      },
      product: {
        name: '',
        product_code: '',
        product_family_id: '',
        description: '',
        ad_type: '',
        campaign_name_convention: '',
        media_plan_convention: '',
        kpi_unit_map_metric: {},
        is_active: false,
        is_channel: false,
        is_media: false,
      },

      // Repeatable Form
      maps: [],
      mapsNextId: 2,
    }
  },
  methods: {
    doCloseModal() {
      this.$refs['product-create-modal'].hide()
    },

    async doLoadData() {
      this.showOverlay = false
    },

    async validationForm() {
      let alertResult
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        this.showOverlay = true

        this.product.kpi_unit_map_metric = AxiosBodyService.productMapping(this.maps)
        const body = this.product

        try {
          const response = await axiosInstance.post('products', body)

          alertResult = await this.$swal({ ...SweetAlert.success, text: response.data.message })

          if (alertResult.value) {
            this.$emit('success')
            this.product = { ...this.original }
            this.$refs['product-create-modal'].hide()
          }
        } catch (error) {
          this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        } finally {
          this.showOverlay = false
        }
      }
    },

    addRow() {
      this.maps.push({ id: this.mapsNextId, property: '', value: '' })
      this.mapsNextId += 1
    },

    deleteRow(index) {
      this.maps.splice(index, 1)
    },
  },
}
</script>
